import {useState,useEffect} from "react"
import {
  Edit,
  SimpleForm,
  List,
  DateField,
  Datagrid,
  TextInput,
  TextField,
  BooleanInput,
  useEditContext,
  useRecordContext,
  UseRecordContextParams,
  Button,
  useDataProvider,
  ShowButton,
  useNotify,
  useRefresh,
  useListController,
  useListContext,
} from "react-admin";
// import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
const postRowStyle = (record: { nb_views: number }, index: any) => ({
  backgroundColor: index % 2 === 0 ? "#EEE" : "white",
});

const postFilters = [
  <TextInput label="Search" source="q" alwaysOn />
]
const ResolveField = (props: UseRecordContextParams<IReport> | undefined) => {
  const record = useRecordContext(props);
  return record.isResolved ? <span>Yes</span> : <span>No</span>;
}
const YesField = (props:UseRecordContextParams<IReport>|undefined)=>{
  const record = useRecordContext(props);
  return record.reportedContent?.details?.__deleted? <span>Yes</span>:<span>No</span>

}



export const ReportsList = (props: any) => {
  const listController = useListController();
  const listContext = useListContext(listController);

  useEffect(() => {
    const currentUrl = window.location.href;
    const fresh = currentUrl.split("/").pop();

    if (fresh === "reports") {
      listContext.setFilters({}, []);
      // set the url to the current url without the query string
      window.history.replaceState({}, "", currentUrl.split("?")[0]);

    }
  }, [listContext.filterValues]);
  return (
    <List {...props} sort={{field:'createdAt',order:'DESC'}} filter={{ withDeleted: true }} filters={postFilters}>
      <Datagrid rowClick="edit" rowStyle={postRowStyle} bulkActionButtons={false} sx={{
        "& .RaDatagrid-headerCell": {
          fontSize: "1rem",
          fontWeight: 800,
        }}}>
        <TextField source="description"  />
        <TextField
          label="Reported Content"
          source="reportedContent.contentType"
        />
        <TextField label="User" source="user.username" />
        <TextField
          label="Publication User"
          source="reportedContent.details.publication.username"
        />
        {/* <TextField label="Resolved" source="isResolved" /> */}
        <ResolveField label="Resolved" source="isResolved"  />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export const ReportsEditList = () => {
  const { record, isLoading } = useEditContext<IReport>();
  const notify = useNotify();
  const refresh = useRefresh();

  const [showDetails,setShowDetails] = useState<boolean>(false)
  const dataProvider = useDataProvider();
  const onDeleteContent=(contentData:string)=>{
    const allContentDetails:{id:string,contentType:string,contentId:string,deleted:boolean,postId:string,messageThreadId:string,isResolved:boolean} = JSON.parse(contentData);
    // dataProvider.update()

    dataProvider.update("reports",{
      data: {
        id: allContentDetails.id,
        isResolved:allContentDetails.isResolved,
        reportedContent:{
          contentType: allContentDetails.contentType,
          id: allContentDetails.contentId,
          details:{
            __deleted:allContentDetails.deleted,
            postId:allContentDetails.postId,
            messageThreadId:allContentDetails.messageThreadId
          }
        }
      },
      id:allContentDetails.id,
      previousData: {}
    })
    allContentDetails.deleted? notify(`removed successfully`):notify(`restore successfully`);
    refresh();
  }
  if (isLoading) return null;
  return (
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>ID</p>
          <TextInput
            disabled
            label={false}
            fullWidth
            source="id"
            sx={{
              marginTop: "0",
              marginBottom: "0",
              "& .MuiFilledInput-input": {
                paddingY: "5px",
              }
            }}
          />

        </Grid>
        <Grid item xs={4}>
          <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Description</p>
          <TextInput
            disabled
            label={false}
            fullWidth
            source="description"
            sx={{
              marginTop: "0",
              marginBottom: "0",
              "& .MuiFilledInput-input": {
                paddingY: "5px",
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Reported Content Type</p>
          <TextInput
            disabled
            label={false}
            fullWidth
            source="reportedContent.contentType"
            sx={{
              marginTop: "0",
              marginBottom: "0",
              "& .MuiFilledInput-input": {
                paddingY: "5px",
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Reported Content ID</p>
            <TextInput
              disabled
              label={false}
              fullWidth
              source="reportedContent.id"
              sx={{
                marginTop: "0",
                marginBottom: "0",
                "& .MuiFilledInput-input": {
                  paddingY: "5px",
                }
              }}
            />
        </Grid>
        <Grid item xs={4}>
          <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>User</p>
          <TextInput
            disabled
            label={false}
            fullWidth
            source="user.username"
            sx={{
              marginTop: "0",
              marginBottom: "0",
              "& .MuiFilledInput-input": {
                paddingY: "5px",
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Is Resolved</p>
          <BooleanInput
            label={false}
            fullWidth
            disabled
            source="isResolved"
            sx={{
              marginTop: "0",
              marginBottom: "0",
              "& .MuiFilledInput-input": {
                paddingY: "5px",
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Created At</p>
          <TextInput
            disabled
            label={false}
            fullWidth

            source="createdAt"
            sx={{
              marginTop: "0",
              marginBottom: "0",
              "& .MuiFilledInput-input": {
                paddingY: "5px",
              }
            }}
          />
        </Grid>

        {
          record?.reportedContent?.contentType ==='comment' && record?.reportedContent?.details && (
            <>
            <Grid item xs={4}>
            <p style={{ fontSize: "small", marginTop: 0, marginBottom: '15px' }}>Comment Deleted</p>
              <YesField source="reportedContent.details.__deleted" />
              </Grid>
            <Grid item xs={4}>

            {/* <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Delete Comment</p>
              <BooleanInput
                label={false}
                fullWidth

                source="reportedContent.details.__deleted"
              /> */}
               <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Action</p>
              {/* <BooleanInput
                label={false}
                fullWidth
                source="reportedContent.details.suspended" */}
              {/* /> */}
              {record.reportedContent.details.__deleted?(
                  <Button label={`RESTORE COMMENT`} onClick={(e)=>onDeleteContent(e.currentTarget.value)}
                  variant="contained"
                  value={JSON.stringify({id:record?.id,postId:record?.reportedContent?.details?.postId,contentId:record?.reportedContent.id,deleted:false,contentType:record?.reportedContent?.contentType,messageThreadId:"",isResolved:record.isResolved})}/>

                ):(<Button disabled label={`REMOVE COMMENT`} onClick={(e)=>onDeleteContent(e.currentTarget.value)}  variant="contained"
                value={JSON.stringify({id:record?.id,postId:record?.reportedContent?.details?.postId,contentId:record?.reportedContent.id,deleted:true,contentType:record?.reportedContent?.contentType,messageThreadId:"",isResolved:record.isResolved})}/>)}

            </Grid>
            </>

          )
        }
          {record?.reportedContent?.contentType==="post" && record?.reportedContent?.details &&(
            <>

            <Grid item xs={4}>
              <p style={{ fontSize: "small", marginTop: 0, marginBottom: '15px' }}>Post Deleted</p>
              <YesField source="reportedContent.details.__deleted" />
            </Grid>
            <Grid item xs={4}>
            {record.reportedContent.details.__deleted?(
                  <Button label="Restore Post" sx={{mt:'1.55rem'}}  onClick={(e)=>onDeleteContent(e.currentTarget.value)}
                  variant="contained"
                  value={JSON.stringify({id:record?.id,postId:"",contentId:record?.reportedContent.id,deleted:false,contentType:'post',messageThreadId:"",isResolved:record.isResolved})}/>

                ):(<Button label="Remove Post" sx={{mt:'1.55rem'}} onClick={(e)=>onDeleteContent(e.currentTarget.value)}  variant="contained"
                value={JSON.stringify({id:record?.id,postId:"",contentId:record?.reportedContent.id,deleted:true,contentType:'post',messageThreadId:"",isResolved:record.isResolved})}/>)}
            </Grid>

              {
                record.reportedContent.details?.creatorId && (
                  <Grid item xs={4}>
                  <Button variant="contained" label="Open Creator Profile" sx={{mt:'1.55rem'}} onClick={()=>{
                    const url = "#/creators/"+record?.reportedContent?.details?.creatorId;
                    window.open(url);
                  }}/>
                  </Grid>

                )
              }

            <Grid item xs={4} >


               <Button variant="contained" style={{marginTop:'1.55rem'}} onClick={()=>{
                 const newUrl = "#/posts/" +  record.reportedContent.id;
                 window.open(newUrl);
               }}
             label="Show Reported Content"/>
     </Grid>
          </>
        )}
        {
          record?.reportedContent?.contentType==="user" && record?.reportedContent?.details &&(
            <>

            <Grid item xs={4}>
            <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Action</p>
              {/* <BooleanInput
                label={false}
                fullWidth
                source="reportedContent.details.suspended" */}
              {/* /> */}
              {record.reportedContent.details.suspended?(
                  <Button label={`RESUME ${record?.reportedContent?.contentType}`} onClick={(e)=>onDeleteContent(e.currentTarget.value)}
                  variant="contained"
                  value={JSON.stringify({id:record?.id,postId:"",contentId:record?.reportedContent.id,deleted:false,contentType:record?.reportedContent?.contentType,messageThreadId:"",isResolved:record.isResolved})}/>

                ):(<Button  label={`SUSPEND ${record?.reportedContent?.contentType}`} onClick={(e)=>onDeleteContent(e.currentTarget.value)}  variant="contained"
                value={JSON.stringify({id:record?.id,postId:"",contentId:record?.reportedContent.id,deleted:true,contentType:record?.reportedContent?.contentType,messageThreadId:"",isResolved:record.isResolved})}/>)}
            </Grid>
            <Grid item xs={4}>
                <p style={{ fontSize: "small", marginTop: 0, marginBottom: '15px' }}>User Suspended</p>
                {record.reportedContent.details.suspended?<p>Yes</p>:<p>No</p>}
            </Grid>
            <Grid item xs={4} >
                <Button variant="contained" sx={{mt:'1.55rem'}} label="Show Reported Content" onClick={()=>{
                  const newUrl = "#/users/" +  record.reportedContent.id;
                  window.open(newUrl);
                }}/>
            </Grid>
            </>
          )
        }
        {
          record?.reportedContent?.contentType === "message" && (
            <>
            <Grid item xs={4}>
            <p style={{ fontSize: "small", marginTop: 0, marginBottom: '15px' }}>Message Deleted</p>
            <YesField source="reportedContent.details.__deleted" />
          </Grid>
          <Grid item xs={4}>
            <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Action</p>
            {record.reportedContent.details.__deleted?(
              <Button label={`RESTORE Message`} onClick={(e)=>onDeleteContent(e.currentTarget.value)}
              variant="contained"
              value={JSON.stringify({id:record?.id,postId:"",contentId:record?.reportedContent.id,deleted:false,contentType:record?.reportedContent?.contentType,messageThreadId:record?.reportedContent?.details?.messageThreadId,isResolved:record.isResolved})}/>

            ):(<Button  label={`REMOVE Message`} onClick={(e)=>onDeleteContent(e.currentTarget.value)}  variant="contained"
            value={JSON.stringify({id:record?.id,postId:"",contentId:record?.reportedContent.id,deleted:true,contentType:record?.reportedContent?.contentType,messageThreadId:record?.reportedContent?.details?.messageThreadId,isResolved:record.isResolved})}/>)}
          </Grid>
          </>
          )
        }
         {!showDetails && ["message","comment"].includes(record?.reportedContent?.contentType as string) && record?.reportedContent?.details &&(
          <Grid item xs={4}>
            <ShowButton label="Show Reported Content" variant="contained"onClick={()=>setShowDetails(true)} sx={{mt:'1rem'}}/>
          </Grid>
        )}
        {
          record?.reportedContent?.details.messageThreadId && (
            <Grid item xs={4}>
              <Button label="See Message Thread" variant="contained" sx={{mt:'1rem'}} onClick={()=>{
                   const newUrl = "#/messageThreads/" +  record.reportedContent.details.messageThreadId;
                   window.open(newUrl);
              }}/>
            </Grid>
          )
        }

        {showDetails && record?.reportedContent?.contentType === "message" &&
          record?.reportedContent?.details && (
            <>
              <Grid item xs={4}>
                <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Message ID</p>
                <TextInput
                  disabled
                  label={false}
                  fullWidth

                  source="reportedContent.id"
                  sx={{
                    marginTop: "0",
                    marginBottom: "0",
                    "& .MuiFilledInput-input": {
                      paddingY: "5px",
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Message Thread ID</p>
                <TextInput
                  disabled
                  label={false}
                  fullWidth

                  source="reportedContent.details.messageThreadId"
                  sx={{
                    marginTop: "0",
                    marginBottom: "0",
                    "& .MuiFilledInput-input": {
                      paddingY: "5px",
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Message</p>
                <TextInput
                  disabled
                  label={false}
                  fullWidth
                  multiline
                  source="reportedContent.details.text"
                  sx={{
                    marginTop: "0",
                    marginBottom: "0"
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Sender Id</p>
                <TextInput
                  disabled
                  label={false}
                  fullWidth
                  source="reportedContent.details.senderId"
                  sx={{
                    marginTop: "0",
                    marginBottom: "0",
                    "& .MuiFilledInput-input": {
                      paddingY: "5px",
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Sender Type</p>
                <TextInput
                  disabled
                  label={false}
                  fullWidth

                  source="reportedContent.details.senderType"
                  sx={{
                    marginTop: "0",
                    marginBottom: "0",
                    "& .MuiFilledInput-input": {
                      paddingY: "5px",
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Sender Name</p>
                <TextInput
                  disabled
                  label={false}
                  fullWidth

                  source="reportedContent.details.senderData.username"
                  sx={{
                    marginTop: "0",
                    marginBottom: "0",
                    "& .MuiFilledInput-input": {
                      paddingY: "5px",
                    }
                  }}
                />
              </Grid>

            </>
          )}

        { showDetails && (record?.reportedContent?.contentType === "creator" && (
          <>
            <Grid item xs={4}>
            <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Reported User ID</p>
              <TextInput
                disabled
                label={false}
                fullWidth

                source="reportedContent.details.id"
              />
            </Grid>
            <Grid item xs={4}>
            <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Reported User Email</p>
              <TextInput
                disabled
                label={false}
                fullWidth

                source="reportedContent.details.email"
              />
            </Grid>
            <Grid item xs={4}>
            <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Reported User UserName</p>
              <TextInput
                disabled
                label={false}
                fullWidth

                source="reportedContent.details.username"
              />
            </Grid>
            <Grid item xs={4}>
            <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>User Is Active</p>
              <BooleanInput
                disabled
                label={false}
                fullWidth

                source="reportedContent.details.isActive"
              />
            </Grid>
            <Grid item xs={4}>
            <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>User Is Deleted</p>
              <BooleanInput
                disabled
                label={false}
                fullWidth

                source="reportedContent.details.deleted"
              />
            </Grid>
            <Grid item xs={4}>
            <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Action</p>
              {record.reportedContent.details.suspended?(
                  <Button label={`RESUME ${record?.reportedContent?.contentType}`} onClick={(e)=>onDeleteContent(e.currentTarget.value)}
                  variant="contained"
                  value={JSON.stringify({id:record?.id,postId:"",contentId:record?.reportedContent.id,deleted:false,contentType:record?.reportedContent?.contentType,messageThreadId:"",isResolved:record.isResolved})}/>

                ):(<Button  label={`SUSPEND ${record?.reportedContent?.contentType}`} onClick={(e)=>onDeleteContent(e.currentTarget.value)}  variant="contained"
                value={JSON.stringify({id:record?.id,postId:"",contentId:record?.reportedContent.id,deleted:true,contentType:record?.reportedContent?.contentType,messageThreadId:"",isResolved:record.isResolved})}/>)}

            </Grid>
          </>
        ))}

        {showDetails && record?.reportedContent?.contentType === "comment" && (
          <>
            <Grid item xs={4}>
            <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Parent Post ID</p>
              <TextInput
                disabled
                label={false}
                fullWidth

                source="reportedContent.details.postId"
              />
            </Grid>
            <Grid item xs={4}>
            <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Comment ID</p>
              <TextInput
                disabled
                label={false}
                fullWidth

                source="reportedContent.details.id"
              />
            </Grid>
            {
              record?.reportedContent?.details?.appUserId && (
                <Grid item xs={4}>
                  <Button sx={{mt:'2.3rem'}} label="Open User Profile" variant="contained" onClick={()=>{
                    const url = `/#/users/${record?.reportedContent?.details?.appUserId}`
                    window.open(url);
                  }}/>
              </Grid>

              )
            }
            <Grid item xs={4}>
            <p style={{ fontSize: "small", marginTop: 0, marginBottom: '5px' }}>Comment Text</p>
              <TextInput
                disabled
                label={false}
                fullWidth

                source="reportedContent.details.text"
              />
            </Grid>

          </>
        )}
      </Grid>
    </SimpleForm>
  );
};
export const ReportsEdit = () => {
  return (
    <Edit  title={"Reported Content"}>
      <ReportsEditList />
    </Edit>
  );
};

export interface ReportedContent {
  contentType: string;
  id: string;
  details: any;
}

export interface IReport {
  description: string;
  id: string;
  reportedContent: ReportedContent;
  isResolved: boolean;
  createdAt: Date;
  updatedAt: Date;
}