import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  DateTimeInput,
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  Validator,
  FunctionField,
  FileField,
  Button,
  Pagination,
  useRefresh,
  useNotify,
  useListController,
  useListContext,
} from "react-admin";
import { postsDataProvider } from "./dataProvider";
import { useParams } from "react-router-dom";

const postRowStyle = (record: { nb_views: number }, index: any) => ({
  backgroundColor: index % 2 === 0 ? "#EEE" : "white",
});

const filters = [
  <TextInput label="Search" source="creator" alwaysOn />,
  <TextInput label="Browse All" source="browse" defaultValue="browse" />
];

const PostPagination = () => {
  return (
    <Pagination rowsPerPageOptions={[20, 50, 100, 200]}/>
  )
}


export const PostList = (props: any) => {
  const listController = useListController();
  const listContext = useListContext(listController);

  React.useEffect(() => {
    const currentUrl = window.location.href;
    const fresh = currentUrl.split("/").pop();

    if (fresh === "posts") {
      listContext.setFilters({}, []);
      // set the url to the current url without the query string
      window.history.replaceState({}, "", currentUrl.split("?")[0]);

    }
  }, [listContext.filterValues]);
  return(
  <List
    {...props}
    filters={filters}
    pagination={<PostPagination />}
  >
    <Datagrid
      rowClick="edit"
      sx={{
        "& .RaDatagrid-headerCell": {
          fontSize: "1rem",
          fontWeight: 800,
        },
      }}
      bulkActionButtons={false}
      rowStyle={postRowStyle}
    >
      <TextField label="Creator" source="username" />
      <TextField label="Id" source="id" />
      <TextField label="Caption" source="text"   />
      <DateField label="Created At" source="createdAt" />
    </Datagrid>
  </List>
)};
export const CustomTextInput = ({
  label,
  source,
  disabled,
  validate,
  type = "text",
  multiline = false,
}: {
  label: string;
  source: string;
  disabled?: boolean;
  validate?: Validator | Validator[] | undefined;
  type?: "date" | "text" | "boolean";
  multiline?: boolean;
}) => {
  return (
    <>
      <p style={{ fontSize: "small", marginTop: 0, marginBottom: "5px" }}>
        {label}
      </p>
      {type === "text" && (
        <TextInput
          disabled={!!disabled}
          validate={validate}
          label={false}
          source={source}
          multiline={multiline}
          sx={{
            marginTop: "0",
            marginBottom: "0",
            "& .MuiFilledInput-input": {
              paddingY: "5px",
              width: "400px",
            },
            "& .MuiFilledInput-root": {
              paddingY: "5px",
            },
          }}
        />
      )}
      {type === "boolean" && (
        <BooleanInput
          disabled={!!disabled}
          validate={validate}
          label={false}
          source={source}
          sx={{
            marginTop: "0",
            marginBottom: "0",
            "& .MuiFilledInput-input": {
              paddingY: "5px",
              width: "400px",
            },
            "& .MuiFilledInput-root": {
              paddingY: "5px",
            },
          }}
        />
      )}
      {type === "date" && (
        <DateTimeInput
          disabled={!!disabled}
          validate={validate}
          label={false}
          source={source}
          sx={{
            marginTop: "0",
            marginBottom: "0",
            "& .MuiFilledInput-input": {
              paddingY: "5px",
              width: "400px",
            },
          }}
        />
      )}
    </>
  );
};

export const PostEdit = () => {
  const refresh = useRefresh();
  const params = useParams();
  const notify = useNotify();
  const id = params?.id as string;
  const [post, setPost] = React.useState<any>();
  const dataProvider = postsDataProvider;

  React.useEffect(() => {
    dataProvider
      .getOne("post", { id: id })
      .then((postData) => {
        setPost(postData);
      })
      .catch((err) => console.error(err));
  }, [id, dataProvider]);

  const onDeletePostHandler = () => {
    dataProvider
      .update("posts", {
        id: id,
        data: {
          id: id,
          deleted: !post?.deleted,
        },
        previousData: {},
      })
      .then((updatedPost) => {
        setPost(updatedPost?.data);
        notify(!updatedPost?.data?.deleted ? "Post Restored" : "Post Deleted");
        refresh();
      })
      .catch((err) => {
        notify("Operation failed");
      });
  };
  /**
   * Deletes a comment under a post
   * @param ref Reference path to the comment under post collection
   */
  const onDeleteCommentHandler =(ref:string,id:string, deleted: boolean)=>{
    dataProvider
      .update("posts", {
        id: id,
        data: {
          id: id,
          commentPath: ref,
          deleted: !deleted,
          comment:true

        },
        previousData: {},
      })
      .then((updatedPost) => {
        setPost(updatedPost?.data);
        notify("Operation Completed")
        refresh();
      })
      .catch((err) => {
        notify("Operation failed");
      });
  }

  return (
    <Edit title={"Posts"}>
      <SimpleForm
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            flex: 1,
            marginRight: 10,
          }}
        >
          <h3>Details</h3>
          <CustomTextInput label="Id" disabled source="id" />
          <CustomTextInput
            label="Creator"
            disabled
            source="publication.username"
          />
          <CustomTextInput label="Pinned" disabled source="isPinned" />
          <CustomTextInput label="Exclusive" disabled source="isExclusive" />
          <CustomTextInput label="Asset Type" disabled source="asset.type" />
          <CustomTextInput label="Created At" disabled source="createdAt" />
          <CustomTextInput label="Updated At" disabled source="updatedAt" />
          <CustomTextInput label="Deleted" disabled source="deleted" />
          <CustomTextInput
            label="Views"
            disabled
            source="asset.actionCount.views"
          />
          <CustomTextInput
            label="Likes"
            disabled
            source="asset.actionCount.likes"
          />
          <div style={{ flexDirection: "row" }}>
            <Button
              label={post?.deleted === true ? "Restore Post" : "Delete Post"}
              variant="contained"
              disabled
              onClick={onDeletePostHandler}
            />
          </div>
        </div>
        <div
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3>Asset</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FunctionField
              label="Asset"
              render={(record: any) => {
                switch (record?.asset?.type) {
                  case "image":
                    return (
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src={record?.asset?.originalUrl}
                      />
                    );
                  case "video":
                    return (
                      <video
                        style={{ width: 360, height: "auto" }}
                        src={record?.asset?.originalUrl}
                        controls
                      />
                    );
                  case "audio":
                    return <audio src={record?.asset?.originalUrl} controls />;
                  case "pdf":
                    return (
                      <FileField
                        source="asset.originalUrl"
                        target="_blank"
                        title="Click to open PDF"
                      />
                    );
                  default:
                    <span>No Asset</span>;
                }
              }}
            />
          </div>
          <div style={{
            display: "flex",
            justifyContent:"center",
            flexDirection:"column"
          }}>
          <CustomTextInput label="Caption" disabled source="text" />
          </div>
          <FunctionField
            render={(record: any) => {
                const comments = record?.comments as any[];
                const comp = (
                  <div>
                    <h3>Comments</h3>
                    {comments?.map((comment: any) => (
                      <div
                        style={{
                          padding: 10,
                          width: "auto",
                          margin: 10,
                          marginLeft: 0,
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {comment?.userName}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>{comment?.text}</div>
                          <Button
                            variant="contained"
                            disabled
                            label={
                              comment?.__deleted
                                ? "Restore Comment"
                                : "Delete Comment"
                            }
                            onClick={() => {
                              onDeleteCommentHandler(
                                comment?.ref,
                                comment?.id,
                                comment?.__deleted
                              );
                            }}
                          />
                        </div>
                        {comment?.innerComment?.length > 0 && (
                          <div>
                            <h4 style={{
                              marginBottom: 0
                            }}>Replies</h4>
                            {comment?.innerComment?.map((comment: any) => (
                              <div
                                style={{
                                  padding: 10,
                                  borderRadius: 15,
                                  width: "auto",
                                  paddingRight: 0,
                                  margin: 10,
                                  marginTop: 0,
                                  marginRight: 0,
                                }}
                              >
                                <div>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {comment?.userName}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>{comment?.text}</div>
                                  <Button
                                    variant="contained"
                                    label={
                                      comment?.__deleted
                                        ? "Restore Comment"
                                        : "Delete Comment"
                                    }
                                    onClick={() => {
                                      onDeleteCommentHandler(
                                        comment?.ref,
                                        comment?.id,
                                        comment?.__deleted
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                );

                return comp;
            }}
          />
        </div>
      </SimpleForm>
    </Edit>
  );
};
