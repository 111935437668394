import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceManyField,
  Button,
  useDataProvider,
  FunctionField,
  DateField,
  useListController,
  useListContext,
} from "react-admin";
import { useParams } from "react-router-dom";
const postRowStyle = (record: { nb_views: number }, index: any) => ({
  backgroundColor: index % 2 === 0 ? "#EEE" : "white",
});

const userFilters = [<TextInput label="Search" source="q" alwaysOn />];

export const MessageThreadList = (props: any) => {
  const listController = useListController();
  const listContext = useListContext(listController);

  React.useEffect(() => {
    const currentUrl = window.location.href;
    const fresh = currentUrl.split("/").pop();

    if (fresh === "messageThreads") {
      console.log('clear')
      listContext.setFilters({}, []);
      // set the url to the current url without the query string
      window.history.replaceState({}, "", currentUrl.split("?")[0]);

    }
  }, [listContext.filterValues]);

  return (
    <List {...props} filter={{ withDeleted: true }} filters={userFilters}>
      <Datagrid
        rowStyle={postRowStyle}
        rowClick="edit"
        bulkActionButtons={false}
        sx={{
          "& .RaDatagrid-headerCell": {
            fontSize: "1rem",
            fontWeight: 800,
          },
        }}
      >
        <TextField label="Message Thread ID" source="id" />
        <TextField label="Creator" source="publication.username" />
        <TextField label="User" source="appUser.username" />
        <TextField label="Deleted" source="deletedText" />
        <TextField label="Tip Total (USD)" source="tipTotal" />
      </Datagrid>
    </List>
  );
};

export const MessageThreadEdit = () => {
  const params = useParams();
  const dataProvider = useDataProvider();

  const onApproveClickHandler = (e: any) => {
    const messageId = e.target.parentNode.parentNode.parentNode.children[0]
      .children[1].children[0].innerText as string;

    const currentStatus = e.target.parentNode.parentNode.parentNode.children[0]
      .children[3].children[0].innerText as string;

    if (currentStatus === "approved") {
      alert("Message already approved");
      return;
    }

    dataProvider.update("messages", {
      id: messageId,
      data: {
        id: messageId,
        message_thread_id: params.id as string,
        approvalStatus: "approved",
      },
      previousData: {},
    });

    e.target.parentNode.parentNode.parentNode.children[0].children[3].children[0].innerText =
      "approved";
  };

  const onRejectClickHandler = (e: any) => {
    const messageId = e.target.parentNode.parentNode.parentNode.children[0]
      .children[1].children[0].innerText as string;

    const currentStatus = e.target.parentNode.parentNode.parentNode.children[0]
      .children[3].children[0].innerText as string;

    if (currentStatus === "rejected") {
      alert("Message already rejected");
      return;
    }

    dataProvider.update("messages", {
      id: messageId,
      data: {
        id: messageId,
        message_thread_id: params.id as string,
        approvalStatus: "rejected",
      },
      previousData: {},
    });

    e.target.parentNode.parentNode.parentNode.children[0].children[3].children[0].innerText =
      "rejected";
  };

  const CustomEmpty = () => (
    <p
      style={{
        color: "red",
        fontSize: "small",
        marginTop: 0,
        marginBottom: "5px",
      }}
    >
      There are no messages on this thread.
    </p>
  );

  return (
    <Edit title={"Message Threads"}>
      <SimpleForm>
        <p style={{ fontSize: "small", marginTop: 0, marginBottom: "5px" }}>
          Id
        </p>
        <TextInput
          disabled
          label={false}
          source="id"
          sx={{
            marginTop: "0",
            marginBottom: "0",
            "& .MuiFilledInput-input": {
              paddingY: "5px"
            },
          }}
        />
        <p style={{ fontSize: "small", marginTop: 0 }}>Creator Username</p>
        <TextInput
          disabled
          label={false}
          source="publication.username"
          sx={{
            marginTop: "0",
            marginBottom: "0",
            "& .MuiFilledInput-input": {
              paddingY: "5px",
            },
          }}
        />
        <p style={{ fontSize: "small", marginTop: 0 }}>User Username</p>
        <TextInput
          disabled
          label={false}
          source="appUser.username"
          sx={{
            marginTop: "0",
            marginBottom: "0",
            "& .MuiFilledInput-input": {
              paddingY: "5px",
            },
          }}
        />
        <p style={{ fontSize: "small", marginTop: 0 }}>Tip Total (USD)</p>
        <TextInput
          disabled
          label={false}
          source="tipTotal"
          sx={{
            marginTop: "0",
            marginBottom: "0",
            "& .MuiFilledInput-input": {
              paddingY: "5px",
            },
          }}
        />
        <ReferenceManyField
          label="Messages"
          reference="messages"
          target="message_thread_id"
        >
          <Datagrid bulkActionButtons={false} empty={<CustomEmpty />}>
            <TextField label="Message ID" source="id" />
            <TextField label="Text" source="text" />
            <TextField label="Is Reported" source="isReported" />
            <TextField label="Status" source="approvalStatus" />
            <DateField label="Created At" source="createdAt" />
            <TextField label="Tip Amount (USD)" source="tip.amount" />
            <TextField label="Sender" source="senderId" />
            <TextField label="Receiver" source="recipientId" />
            {/* <BooleanField label="Read" source="isRead" /> */}
            <TextField label="Read" source="isReadText" />
            {/* <BooleanField label="Deleted" source="deleted" /> */}
            <TextField label="Deleted" source="deletedText" />
            <FunctionField
              label="Approve"
              disabled
              render={(record: any) => {
                if (record.approvalStatus === "approved") {
                  return (
                    <Button
                      label="Approve"
                      disabled
                      variant="contained"
                      onClick={(e: any) => onApproveClickHandler(e)}
                    />
                  );
                }

                return (
                  <Button
                    label="Approve"
                    variant="contained"
                    onClick={(e: any) => onApproveClickHandler(e)}
                  />
                );
              }}
            />
            <FunctionField
              label="Reject"
              render={(record: any) => {
                if (record.approvalStatus === "rejected") {
                  return (
                    <Button
                      label="Reject"
                      disabled
                      variant="contained"
                      onClick={(e: any) => onRejectClickHandler(e)}
                    />
                  );
                }

                return (
                  <Button
                    label="Reject"
                    disabled
                    variant="contained"
                    onClick={(e: any) => onRejectClickHandler(e)}
                  />
                );
              }}
            />
          </Datagrid>
        </ReferenceManyField>
      </SimpleForm>
    </Edit>
  );
};
