// NOTE: need to personalize login to show that we're using email and password, not username and password. To do this, need to create separate login adn logout components. Ref: components

interface LoginObject {
  username: string,
  password: string
}

const authProvider = {
  login: loginProvider,
  checkAuth: () => {
    return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject()
  },
  getPermissions: () => {
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  checkError: errorProvider
};

async function errorProvider(error:any) {
  const status = error.status;
  if (status === 401 || status === 403) {
    localStorage.removeItem('auth');
    return Promise.reject();
  }
  // other error code (404, 500, etc): no need to log out
  return Promise.resolve();
}

async function loginProvider(loginObject: LoginObject) {
  const serverUrl = process.env.REACT_APP_BACKEND_SERVER
  const loginEndpoint = `${serverUrl}/auth/login`
  const request = new Request(loginEndpoint, {
    method: 'POST',
    body: JSON.stringify({
      username: loginObject.username,
      password: loginObject.password
    }),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
  try {
    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const auth = await response.json();
    localStorage.setItem('auth', JSON.stringify(auth));
  } catch {
    throw new Error('Invalid email or password');
  }
}

export default authProvider;