// in src/App.js
import * as React from "react";
import { Admin, Resource, combineDataProviders } from "react-admin";
import {
  adminsDataProvider,
  categoriesDataProvider,
  creatorsDataProvider,
  messageThreadsDataProvider,
  messagesDataProvider,
  postsDataProvider,
  userDataProvider,
  reportsDataProvider,
} from "./dataProvider";

import PeopleIcon from "@mui/icons-material/People";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import DescriptionIcon from "@mui/icons-material/Description";
import ExplicitIcon from "@mui/icons-material/Explicit";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import { AdminList, AdminEdit, AdminCreate } from "./admins";

import { CategoryList } from "./categories"
import { CreatorList,CreatorEdit } from "./creators"
import { PostEdit, PostList } from "./posts"
import { UserCreate, UserEdit, UserList } from "./users"
import { MessageThreadEdit, MessageThreadList } from "./messageThread";
import { ReportsList, ReportsEdit } from "./reports";
import authProvider from "./authProvider";

const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case "admins":
      return adminsDataProvider;
    case "categories":
      return categoriesDataProvider;
    case "creators":
      return creatorsDataProvider;
    case "posts":
      return postsDataProvider;
    case "users":
      return userDataProvider;
    case "reports":
      return reportsDataProvider;
    case 'messageThreads':
      return messageThreadsDataProvider;
    case 'messages':
      return messagesDataProvider;
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});

const App = () => {
  if (!dataProvider) {
    return <div>Loading</div>;
  }

  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider} requireAuth>
      <Resource
        name="creators"
        list={CreatorList}
        edit={CreatorEdit}
        options={{ label: "Creators" }}
        icon={ColorLensIcon}
      />
      <Resource
        name="users"
        options={{ label: "Users" }}
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
        icon={PeopleIcon}
      />
      <Resource
        name="categories"
        list={CategoryList}
        options={{ label: "Categories" }}
        icon={FolderCopyIcon}
      />
      <Resource
        name="reports"
        options={{ label: "Reported Content" }}
        icon={ExplicitIcon}
        list={ReportsList}
        edit={ReportsEdit}
      />
      <Resource
        name="posts"
        list={PostList}
        edit={PostEdit}
        options={{ label: "Posts" }}
        icon={DescriptionIcon}
      />
      <Resource
        name="admins"
        create={AdminCreate}
        list={AdminList}
        edit={AdminEdit}
        options={{ label: "Admins" }}
        icon={VerifiedUserIcon}
      />

      <Resource
        name="messageThreads"
        list={MessageThreadList}
        edit={MessageThreadEdit}
        options={{ label: 'Message Threads' }}
        icon={MarkEmailReadIcon}
      />
    </Admin>
  );
};

export default App;
