import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { omit, pick } from "lodash";
import pluralize from "pluralize";
import camelCase from "camelcase";
import { DataProvider } from "react-admin";

/*
  After some consideration, it seems like it will be easiest to have a master template of
  dataProvider methods and to override them by composition as required

  Each of the core methods are defined at the top and then redefined within each use of
  the dataProvider interface
*/

const serverUrl = process.env.REACT_APP_BACKEND_SERVER;
const { access_token } = JSON.parse(localStorage.getItem("auth") || "{}");
const client = new ApolloClient({
  uri: `${serverUrl}/graphql`,
  headers: { Authorization: `Bearer ${access_token}` },
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

const fields: any = {
  admins: "id",
  categories: "id name isFeatured imageUrl updatedAt createdAt",
  creators: "id email featured acceptedAt createdAt",
  users:
    "id profileUrl username email updatedAt createdAt deleted deletedText suspended reported firstName lastName suspendedAt",
  userPages:
    "id user{username} fanfixUsername instagramUsername snapchatUsername tiktokUsername twitterUsername youtubeUsername isActive updatedAt createdAt",
  messageThreads: "id ",
  messages: "id ",
  reports:"id contentType contentId postId contentDeleted",
  posts:" id  createdAt  creatorId  isExclusive  isPinned  text  publicationId  publication {    username    __typename  }  __typename  asset  deleted comments"
};

const getList = async (resource: string, { sort, pagination, filter }: any) => {
  const { field, order } = sort;
  const { page, perPage } = pagination;
  const result = await client.query({
    query: gql`
          query ($limit: Int, $offset: Int) {
              ${resource}(limit: $limit, offset: $offset){
                ${fields[resource]}
              },
              ${resource}Aggregate {
                count
              }
          }`,
    variables: {
      limit: perPage,
      offset: (page - 1) * perPage,
      order_by: { [field]: order.toLowerCase() },
      where: Object.keys(filter).reduce(
        (prev, key) => ({
          ...prev,
          [key]: { _eq: filter[key] },
        }),
        {}
      ),
    },
  });

  return {
    data: result.data[resource],
    total: result.data[`${resource}Aggregate`]["count"],
  };
};

const getOne = async (resource: string, params: { id: string }) => {
  const resourceSingular = camelCase(pluralize.singular(resource));
  const result = await client.query({
    query: gql`
          query ($id: String!, $withDeleted: Boolean) {
            ${resourceSingular}(id: $id, withDeleted: $withDeleted) {
              ${fields[resource]}
            }
          }`,
    variables: {
      id: String(params.id),
      withDeleted: true,
    },
  });
  return { data: result.data[`${resourceSingular}`] };
};

const getMany = async (resource: string, params: { ids: any }) => {
  const result = await client.query({
    query: gql`
          query ($where: ${resource}_bool_exp) {
              ${resource}(where: $where) {
                  ${fields[resource]}
              }
          }`,
    variables: {
      where: {
        id: { _in: params.ids },
      },
    },
  });
  return { data: result.data[resource] };
};

const getManyReference = async (
  resource: string,
  { target, id, sort, pagination, filter }: any
) => {
  console.log(
    "getManyReference",
    resource,
    target,
    id,
    sort,
    pagination,
    filter
  );
  const { field, order } = sort;
  const { page, perPage } = pagination;
  const result = await client.query({
    query: gql`
          query ($limit: Int, $offset: Int, $order_by: [${resource}_order_by!], $where: ${resource}_bool_exp) {
              ${resource}(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
                  ${fields[resource]}
              }
              ${resource}_aggregate(where: $where) {
                  aggregate {
                      count
                  }
              }
          }`,
    variables: {
      limit: perPage,
      offset: (page - 1) * perPage,
      order_by: { [field]: order.toLowerCase() },
      where: Object.keys(filter).reduce(
        (prev, key) => ({
          ...prev,
          [key]: { _eq: filter[key] },
        }),
        { [target]: { _eq: id } }
      ),
    },
  });
  return {
    data: result.data[resource],
    total: result.data[`${resource}_aggregate`].aggregate.count,
  };
};

const create = async (resource: string, params: { data: any }) => {
  const resourceUpper: string = capitalize(pluralize.singular(resource));
  const result = await client.mutate({
    mutation: gql`
          mutation ($data: Create${resourceUpper}Input!) {
            create${resourceUpper}(create${resourceUpper}Input: $data) {
              ${fields[resource]}
            }
          }`,
    variables: {
      data: omit(params.data, ["__typename"]),
    },
  });
  return {
    data: result.data[`create${resourceUpper}`],
  };
};

const updateMany = async (resource: any, params: { ids: any; data: any }) => {
  await client.mutate({
    mutation: gql`
          mutation ($where: ${resource}_bool_exp!, $data: ${resource}_set_input!) {
              update_${resource}(where: $where, _set: $data) {
                  affected_rows
              }
          }`,
    variables: {
      where: {
        id: { _in: params.ids },
      },
      data: omit(params.data, ["__typename"]),
    },
  });
  return {
    data: params.ids,
  };
};

const update = async (resource: string, params: { id: any; data: any }) => {
  const resourceUpper: string = capitalize(pluralize.singular(resource));
  const result = await client.mutate({
    mutation: gql`
          mutation ($data: Update${resourceUpper}Input!) {
            update${resourceUpper}(update${resourceUpper}Input: $data) {
              ${fields[resource]}
            }
          }`,
    variables: {
      data: omit(params.data, ["__typename", "user", "updatedAt", "createdAt"]),
    },
  });
  return {
    data: result.data[`update${resourceUpper}`],
  };
};

const deleteOne = async (resource: string, params: { id: any }) => {
  const resourceUpper: string = capitalize(pluralize.singular(resource));
  const result = await client.mutate({
    mutation: gql`
          mutation ($id: String!) {
            remove${resourceUpper}(id: $id) {
              ${fields[resource]}
            }
          }`,
    variables: {
      id: params.id,
    },
  });
  return {
    data: result.data[`remove${resource}`],
  };
};

const deleteMany = async (resource: any, params: { ids: any }) => {
  await client.mutate({
    mutation: gql`
          mutation ($where: ${resource}_bool_exp!) {
              delete_${resource}(where: $where) {
                  affected_rows
              }
          }`,
    variables: {
      where: {
        id: { _in: params.ids },
      },
    },
  });
  return {
    data: params.ids,
  };
};

export const adminsDataProvider: DataProvider = {
  getList: async (resource: string, { sort, pagination, filter }: any) => {
    const { field, order } = sort;
    const { page, perPage } = pagination;
    const result = await client.query({
      query: gql`
        query ($limit: Int, $offset: Int, $where: JSON) {
          admins(limit: $limit, offset: $offset,where: $where) {
            id
            user {
              username
              firstName
              lastName
            }
          }
          adminsAggregate(limit: $limit, offset: $offset,where: $where) {
            count
          }
        }
      `,
      variables: {
        limit: perPage,
        offset: (page - 1) * perPage,
        order_by: { [field]: order.toLowerCase() },
        where: Object.keys(filter).reduce(
          (prev, key) => ({
            ...prev,
            [key]: { _eq: filter[key] },
          }),
          {}
        ),
      },
    });

    return {
      data: result.data["admins"],
      total: result.data[`adminsAggregate`]["count"],
    };
  },
  getOne: async (resource: string, params: { id: string }) => {
    const result = await client.query({
      query: gql`
        query ($id: String!, $withDeleted: Boolean) {
          admin(id: $id, withDeleted: $withDeleted) {
            id
            user {
              username
              firstName
              lastName
            }
          }
        }
      `,
      variables: {
        id: String(params.id),
        withDeleted: true,
      },
    });
    return { data: result.data[`admin`] };
  },
  getMany: getMany,
  getManyReference: getManyReference,
  create: create,
  update: update,
  updateMany: updateMany,
  delete: deleteOne,
  deleteMany: deleteMany,
};

export const messageThreadsDataProvider: DataProvider = {
  getList: async (resource: string, { sort, pagination, filter }: any) => {
    const { field, order } = sort;
    const { page, perPage } = pagination;
    const result = await client.query({
      query: gql`
        query ($limit: Int, $offset: Int, $order_by: JSON, $where: JSON) {
          messageThreads(limit: $limit, offset: $offset,order_by: $order_by, where: $where) {
            id
            appUser {
              id
              username
            }
            publication {
              id
              username
            }
            tipTotal
            deleted
            deletedText
          }
          messageThreadsAggregate(where: $where) {
            count
          }
        }
      `,
      variables: {
        limit: perPage,
        offset: (page - 1) * perPage,
        order_by: { [field]: order.toLowerCase() },
        where: Object.keys(filter).reduce(
          (prev, key) => ({
            ...prev,
            [key]: { _eq: filter[key] },
          }),
          {}
        ),
      },
    });

    return {
      data: result.data["messageThreads"],
      total: result.data[`messageThreadsAggregate`]["count"],
    };
  },
  getOne: async (resource: string, params: { id: string }) => {
    const result = await client.query({
      query: gql`
        query ($id: String!) {
          messageThread(id: $id) {
            id
            appUser {
              id
              username
            }
            publication {
              id
              username
            }
            tipTotal
            deleted
            deletedText
            messages
          }
        }
      `,
      variables: {
        id: String(params.id),
      },
    });
    return { data: result.data[`messageThread`] };
  },
  getMany: getMany,
  getManyReference: getManyReference,
  create: create,
  update: update,
  updateMany: updateMany,
  delete: deleteOne,
  deleteMany: deleteMany,
};

export const messagesDataProvider: DataProvider = {
  getList: async (
    resource: string,
    { sort, pagination, filter, ...rest }: any
  ) => {

    const { field, order } = sort;
    const { page, perPage } = pagination;
    const result = await client.query({
      query: gql`
        query ($limit: Int, $offset: Int, $order_by: JSON, $where: JSON) {
          messages(
            limit: $limit
            offset: $offset
            order_by: $order_by
            where: $where
          ) {
            id
            message_thread_id
            isRead
            isReadText
            text
            approvalStatus
            senderId
            senderType
            recipientId
            recipientType
            tip {
              amount
              id
            }
            deleted
            deletedText
          }
          messagesAggregate {
            count
          }
        }
      `,
      variables: {
        limit: perPage,
        offset: (page - 1) * perPage,
        order_by: { [field]: order.toLowerCase() },
        where: Object.keys(filter).reduce(
          (prev, key) => ({
            ...prev,
            [key]: { _eq: filter[key] },
          }),
          {}
        ),
      },
    });

    return {
      data: result.data["messages"],
      total: result.data[`messagesAggregate`]["count"],
    };
  },
  getOne: async (resource: string, params: { id: string }) => {

    const result = await client.query({
      query: gql`
        query ($id: String!, $message_thread_id: String) {
          message(id: $id, message_thread_id: $message_thread_id) {
            id
            message_thread_id
            isRead
            isReadText
            text
            approvalStatus
            senderId
            senderType
            recipientId
            recipientType
            tip {
              amount
              id
            }
            deleted
            deletedText
            createdAt
          }
        }
      `,
      variables: {
        id: String(params.id),
      },
    });
    return { data: result.data[`message`] };
  },
  getMany: getMany,
  getManyReference: async (resource: string, { id }: any) => {
    // const { field, order } = sort;
    // const { page, perPage } = pagination;
    const result = await client.query({
      query: gql`
        query ($message_thread_id: String!) {
          messages(message_thread_id: $message_thread_id) {
            id
            message_thread_id
            isRead
            isReadText
            isApproved
            text
            approvalStatus
            senderId
            senderType
            recipientId
            recipientType
            tip
            deleted
            deletedText
            createdAt
            isReported
          }
          messagesAggregate(message_thread_id: $message_thread_id) {
            count
          }
        }
      `,
      variables: {
        message_thread_id: id,
      },
    });

    return {
      data: result.data["messages"],
      total: result.data[`messagesAggregate`]["count"],
    };
  },
  create: create,
  update: async (resource: string, params: { id: any; data: any }) => {
    const result = await client.mutate({
      mutation: gql`
        mutation ($data: UpdateMessageInput!) {
          updateMessage(updateMessageInput: $data) {
            id
            message_thread_id
            isRead
            isApproved
            text
            approvalStatus
            senderId
            senderType
            recipientId
            recipientType
            tip
            deleted
            createdAt
          }
        }
      `,
      variables: {
        data: params.data
      }
    });
    return {
      data: result.data[`updateMessage`],
    };
  },
  updateMany: updateMany,
  delete: deleteOne,
  deleteMany: deleteMany,
};

export const categoriesDataProvider: DataProvider = {
  getList: getList,
  getOne: getOne,
  getMany: getMany,
  getManyReference: getManyReference,
  create: create,
  update: update,
  updateMany: updateMany,
  delete: deleteOne,
  deleteMany: deleteMany,
};

export const creatorsDataProvider: DataProvider = {
  getList: async (
    resource: string,
    { sort, pagination, filter, ...r }: any
  ) => {
    const { field, order } = sort;
    const { page, perPage } = pagination;
    const result = await client.query({
      query: gql`
        query ($limit: Int, $offset: Int, $order_by: JSON, $where: JSON) {
          creators(
            limit: $limit
            offset: $offset
            order_by: $order_by
            where: $where
          ) {
            id
            email
            type
            createdAt
            acceptedAt
            appliedAt
            isFeatured
            rejectedAt
            bankConnected
            username
          }
          creatorsAggregate(where: $where) {
            count
          }
        }
      `,
      variables: {
        limit: perPage,
        offset: (page - 1) * perPage,
        order_by: { [field]: order.toLowerCase() },
        where: Object.keys(filter).reduce(
          (prev, key) => ({
            ...prev,
            [key]: { _eq: filter[key] },
          }),
          {}
        ),
      },
    });

    return {
      data: result.data[resource],
      total: result.data[`creatorsAggregate`]["count"],
    };
  },
  getOne: async (resource: string, params: { id: string }) => {
    const result = await client.query({
      query: gql`
        query ($id: String!, $withDeleted: Boolean) {
          creator(id: $id, withDeleted: $withDeleted) {
            id
            email
            featured
            acceptedAt
            appliedAt
            rejectedAt
            createdAt
            phoneNumber
            postCount
            publications {
              id
              postCount
            }
            type
            featuredCreatorIds
            featuredCreators {
              id
              username
            }
            deleted
            suspended
            featuredOrder
            isFeatured
            status
            categoryIds
            publicationPrice
            bankConnected
            socialLinks
            foreignAccount
            categories {
              name
              id
            }
            applicationRequest
            user {
              username
              bio
              firstName
              lastName
              profileUrl
            }
          }
        }
      `,
      variables: {
        id: String(params.id),
        withDeleted: true,
      },
    });
    return { data: result.data[`creator`] };
  },
  getMany: getMany,
  getManyReference: getManyReference,
  create: create,
  update: async (resource: string, params: { id: any; data: any; }) => {
    const resourceUpper: string = capitalize(pluralize.singular(resource))
    const result = await client
      .mutate({
        mutation: gql`
            mutation ($data: Update${resourceUpper}Input!) {
              update${resourceUpper}(update${resourceUpper}Input: $data) {
                id
                deleted
                suspended
                applicationRequest
                categoryIds
                featuredOrder
                isFeatured
                featured
                rejectedAt
                acceptedAt
                foreignAccount
              }
            }`,
        variables: {
          data: pick(params.data, [
            "id",
            "deleted",
            "suspended",
            "applicationRequest",
            "categoryIds",
            "featuredOrder",
            "isFeatured",
            "featured",
            "foreignAccount"
          ]),
        },
      });
    return ({
      data: result.data[`update${resourceUpper}`],
    });
  },
  updateMany: updateMany,
  delete: deleteOne,
  deleteMany: deleteMany,
};

export const postsDataProvider: DataProvider = {
  getList: async (resource: string, { sort, pagination, filter }: any) => {
    const { field, order } = sort;
    const { page, perPage } = pagination;
    const result = await client.query({
      query: gql`
        query ($limit: Int, $offset: Int, $order_by: JSON, $where: JSON) {
          posts(
            limit: $limit
            offset: $offset
            order_by: $order_by
            where: $where
          ) {
            id
            createdAt
            creatorId
            isExclusive
            isPinned
            text
            publicationId
            username

          }
          postsAggregate(limit: $limit, offset: $offset, where: $where) {
            count
          }
        }
      `,
      variables: {
        limit: perPage,
        offset: (page - 1) * perPage,
        order_by: { [field]: order.toLowerCase() },
        where: Object.keys(filter).reduce(
          (prev, key) => ({
            ...prev,
            [key]: { _eq: filter[key] },
          }),
          {}
        ),
      },
    });

    return {
      data: result.data[resource],
      total: result.data[`${resource}Aggregate`]["count"],
    };
  },
  getOne: async (resource: string, params: { id: string }) => {
    const result = await client.query({
      query: gql`
        query ($id: String!, $withDeleted: Boolean) {
          post(id: $id, withDeleted: $withDeleted) {
            id
            createdAt
            creatorId
            isExclusive
            isPinned
            text
            publicationId
            publication {
              username
              __typename
            }
            __typename
            asset
            deleted
            comments
          }
        }
      `,
      variables: {
        id: String(params.id),
        withDeleted: true,
      },
    });
    return { data: result.data[`post`] };
  },
  getMany: getMany,
  getManyReference: getManyReference,
  create: create,
  update: update,
  updateMany: updateMany,
  delete: deleteOne,
  deleteMany: deleteMany,
};

export const reportsDataProvider = {
  getList: async (resource: string, { sort, pagination, filter }: any) => {
    const { field, order } = sort;
    const { page, perPage } = pagination;
    const result = await client.query({
      query: gql`
        query ($limit:Int, $offset: Int, $where: JSON) {
          reports(limit:$limit,offset: $offset, where:$where){
            description
            id
            reportedContent
            isResolved
            createdAt
            updatedAt
            user{
              username
            }
            isResolved
          },
          ${resource}Aggregate(limit:$limit,offset: $offset, where:$where) {
            count
          }
        }
      `,
      variables: {
        limit: perPage,
        offset: (page - 1) * perPage,
        order_by: { [field]: order.toLowerCase() },
        where: Object.keys(filter).reduce(
          (prev, key) => ({
            ...prev,
            [key]: { _eq: filter[key] },
          }),
          {}
        ),
      },
    });
    return {
      data: result.data[resource],
      total: result.data[`${resource}Aggregate`][`count`],
    };
  },
  getOne: async (resource: string, params: { id: string }) => {
    const result = await client.query({
      query: gql`
        query ($id: String!, $withDeleted: Boolean) {
          report(id: $id, withDeleted: $withDeleted) {
            description
            id
            reportedContent
            isResolved
            createdAt
            updatedAt
            user {
              username
            }
            isResolved
          }
        }
      `,
      variables: {
        id: String(params.id),
        withDeleted: true,
      },
    });
    return { data: result.data["report"] };
  },
  getMany: getMany,
  getManyReference: getManyReference,
  create: create,
  update: async (resource: string, params: { id: any; data: any }) => {
    const resourceUpper: string = capitalize(pluralize.singular(resource));

    const result = await client.mutate({
      mutation: gql`
            mutation ($data: UpdateReportInput!) {
              updateReport(updateReportInput: $data) {
                id
                contentType
                contentId
                postId
                contentDeleted
                messageThreadId
              }
            }`,
      variables: {
        data: {
          id: String(params.data.id),
          isResolved:Boolean(params.data.isResolved??false),
          contentType: String(params.data.reportedContent.contentType),
          contentId: String(params.data.reportedContent.id),
          postId: String(params.data.reportedContent?.details?.postId ?? ""),
          messageThreadId:String(params.data.reportedContent?.details?.messageThreadId ?? ""),
          contentDeleted: Boolean(
            params.data.reportedContent?.details?.__deleted ?? true
          ),

          // parentId:params.data.
        },
      },
    });
    return {
      data: result.data[`update${resourceUpper}`],
    };
  },
  updateMany: updateMany,
  delete: deleteOne,
  deleteMany: deleteMany,
};

export const userDataProvider = {
  getList: async (resource: string, { sort, pagination, filter }: any) => {
    const { field, order } = sort;
    const { page, perPage } = pagination;
    const result = await client.query({
      query: gql`
            query ($limit: Int, $offset: Int, $order_by: JSON, $where: JSON) {
                users(
                  limit: $limit
                  offset: $offset
                  order_by: $order_by
                  where: $where
                  ) {
                    username,
                    profileUrl,
                    email,
                    createdAt,
                    deleted,
                    type,
                    id
                },
                usersAggregate(limit: $limit, offset: $offset, where: $where) {
                  count
                }
            }`,
      variables: {
        limit: perPage,
        offset: (page - 1) * perPage,
        order_by: { [field]: order.toLowerCase() },
        where: Object.keys(filter).reduce(
          (prev, key) => ({
            ...prev,
            [key]: { _eq: filter[key] },
          }),
          {}
        ),
      },
    });

    return {
      data: result.data[resource],
      total: result.data[`${resource}Aggregate`]["count"],
    };
  },
  getOne: async (resource: string, params: { id: string }) => {
    const resourceSingular = camelCase(pluralize.singular(resource));
    const result = await client.query({
      query: gql`
        query ($id: String!, $withDeleted: Boolean) {
          user(id: $id, withDeleted: $withDeleted) {
            id
            email
            bio
            username
            firstName
            lastName
            deleted
            deletedText
            isAdmin
            disabled
            reported
            suspended
            suspendedAt
            bankConnected
            hasApplication
            createdAt
            updatedAt
            dateOfBirth
          }
        }
      `,
      variables: {
        id: String(params.id),
        withDeleted: true,
      },
    });
    return { data: result.data[`${resourceSingular}`] };
  },
  getMany: getMany,
  getManyReference: getManyReference,
  create: create,
  update:  async (resource: string, params: { id: any; data: any }) => {
    const resourceUpper: string = capitalize(pluralize.singular(resource));
    const result = await client.mutate({
      mutation: gql`
            mutation ($data: Update${resourceUpper}Input!) {
              update${resourceUpper}(update${resourceUpper}Input: $data) {
                id
                isActive
                deleted
                suspended
                disabled
                reported
                isAdmin
              }
            }`,
      variables: {
        data: pick(params.data, ["id", "isActive", "deleted", "suspended", "disabled", "reported", "isAdmin"]),
      },
    });
    return {
      data: result.data[`update${resourceUpper}`],
    };
  },
  updateMany: updateMany,
  delete: deleteOne,
  deleteMany: deleteMany,
};
