import * as React from "react";
import {
  List,
  ImageField,
  Datagrid,
  TextField,
  BooleanField,
} from "react-admin";
const postRowStyle = (record: { nb_views: number }, index: any) => ({
  backgroundColor: index % 2 === 0 ? "#EEE" : "white",
});

//<TextField source="type"/>
export const CategoryList = (props: any) => (
  <List {...props} filter={{ withDeleted: true }}>
    <Datagrid rowStyle={postRowStyle} bulkActionButtons={false}>
      <ImageField sx={{ '& img': { maxWidth: 100, maxHeight: 100, objectFit: 'contain' } }} source="imageUrl" title="profile" />
      <TextField source="name" />
      <BooleanField source="isFeatured" />
    </Datagrid>
  </List>
);
