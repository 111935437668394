import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanInput,
  Edit,
  SimpleForm,
  Validator,
  TextInput,
  useEditContext,
  SelectArrayInput,
  RadioButtonGroupInput,
  ImageField,
  Button,
  FunctionField,
  useUpdate,
  DateTimeInput,
  useListController,
  useListContext,
  Pagination,
  FilterLiveSearch,
  FilterList,
  FilterListItem,
  SavedQueriesList,
} from "react-admin";
import { useSearchParams } from "react-router-dom";
import { Card, CardContent, Chip, Grid } from "@mui/material";
import CustomModal from "./featuredModal";
import MenuItem from "@mui/material/MenuItem";
import PeopleIcon from "@mui/icons-material/People";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import BookmarkIcon from '@mui/icons-material/BookmarkBorder';
import Select, { SelectChangeEvent } from "@mui/material/Select";

const postRowStyle = (record: { nb_views: number }, index: any) => ({
  backgroundColor: index % 2 === 0 ? "#EEE" : "white",
});

const QuickFilter = ({
  label,
}: {
  label: string;
  source?: string;
  defaultValue?: string;
}) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

export const CreatorFilterSidebar = () => (
  <Card >
    <CardContent>
      <FilterLiveSearch />
      <FilterList label="Type" icon={<ColorLensIcon/>}>
        <FilterListItem label="Accepted" value={{type: 'creator'}} />
        <FilterListItem label="Submitted" value={{type: 'application-submitted'}} />
        <FilterListItem label="Pending" value={{type: 'creator-pending'}} />
        <FilterListItem label="Rejected (Subscriber)" value={{type: 'subscriber'}} />
      </FilterList>
      <FilterList label="Browse" icon={<ColorLensIcon/>}>
        <FilterListItem label="Browse All" value={{browse: 'true'}} />
      </FilterList>
    </CardContent>
  </Card>
)

export const CreatorList = (props: any) => {
  const listController = useListController();
  const listContext = useListContext(listController);

  React.useEffect(() => {
    const currentUrl = window.location.href;
    const fresh = currentUrl.split("/").pop();

    if (fresh === "creators") {
      listContext.setFilters({}, []);
      // set the url to the current url without the query string
      window.history.replaceState({}, "", currentUrl.split("?")[0]);

    }
  }, [listContext.filterValues]);

  const PostPagination = () => {
    return (
      <Pagination rowsPerPageOptions={[20, 100]}/>
    )
  }

  return (

    <List
    {...props}
    aside={<CreatorFilterSidebar/>}
    pagination={<PostPagination />}
  >
    <Datagrid
      rowClick="edit"
      rowStyle={postRowStyle}
      bulkActionButtons={false}
      sx={{ "& .RaDatagrid-headerCell": { fontSize: "1rem", fontWeight: 800 } }}
    >
      <TextField source="email" emptyText="[null]"/>
      <TextField source="username" label="Username" emptyText="[null]"/>
      <TextField source="type" emptyText="[null]"/>
      <TextField source="bankConnected" emptyText="Not connected"/>
      <DateField source="rejectedAt" emptyText="[null]"/>
      <DateField source="acceptedAt" emptyText="[null]"/>
      <DateField source="appliedAt" emptyText="[null]"/>
      <DateField source="createdAt" emptyText="[null]"/>
    </Datagrid>
  </List>
  )
};

export const CreatorEdit = () => (
  <Edit title="Creators">
    <EditForm />
  </Edit>
);

export const CustomSelectInput = ({
  label,
  source,
  disabled,
  choices,
}: {
  label: string;
  source: string;
  disabled?: boolean;
  choices: any[];
}) => {
  return (
    <>
      <p style={{ fontSize: "small", marginTop: 0, marginBottom: "5px" }}>
        {label}
      </p>
      <SelectArrayInput source="categoryIds" choices={choices} />
    </>
  );
};

export const CustomTextInput = ({
  label,
  source,
  disabled,
  validate,
  type = "text",
  multiline = false,
}: {
  label: string;
  source: string;
  disabled?: boolean;
  validate?: Validator | Validator[] | undefined;
  type?: "date" | "text" | "boolean";
  multiline?: boolean;
}) => {
  return (
    <>
      <p style={{ fontSize: "small", marginTop: 0, marginBottom: "5px" }}>
        {label}
      </p>
      {type === "text" && (
        <TextInput
          disabled={!!disabled}
          validate={validate}
          fullWidth
          label={false}
          source={source}
          multiline={multiline}
          sx={{
            marginTop: "0",
            marginBottom: "0",
            "& .MuiFilledInput-input": {
              paddingY: "5px",
            },
            "& .MuiFilledInput-root": {
              paddingY: "5px",
            },
          }}
        />
      )}

      {type === "date" && (
        <DateTimeInput
          disabled={!!disabled}
          validate={validate}
          fullWidth
          label={false}
          source={source}
          sx={{
            marginTop: "0",
            marginBottom: "0",
            "& .MuiFilledInput-input": {
              paddingY: "5px",
            },
          }}
        />
      )}
    </>
  );
};

const EditForm = (props: any) => {
  const [params] = useSearchParams();
  const { record, isLoading } = useEditContext<Creator>();
  const [open, setOpen] = React.useState(false);
  const [isFeatured, setIsFeatured] = React.useState(false);
  const [foreignAccount, setForeignAccount] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [update] = useUpdate();
  const [featuredOrder, setFeaturedOrder] = React.useState(0);
  const [creatorApplicationOpen, setCreatorApplicationOpen] =
    React.useState(false);
  const [featuredCreators, setFeaturedCreators] = React.useState<
    { username: string; id: string }[]
  >([]);

  React.useEffect(() => {
    if (record) {
      setFeaturedOrder(record.featuredOrder);
      setFeaturedCreators(record.featuredCreators);
      setIsFeatured(record.isFeatured);
      setForeignAccount(record.foreignAccount);
    }
  }, [record]);

  React.useEffect(() => {
    if (params.get('expand') === 'true') {
      setCreatorApplicationOpen(true);
    }
  }, [params]);

  React.useEffect(() => {
    const currentIndex = featuredCreators?.findIndex(
      (creator) => creator.id === record?.id
    );
    if (featuredOrder > 0 && currentIndex >= 0) {
      const newFeaturedCreators = [...featuredCreators];
      const removed = newFeaturedCreators.splice(currentIndex, 1);
      newFeaturedCreators.splice(featuredOrder - 1, 0, ...removed);
      setFeaturedCreators(newFeaturedCreators);
    }
  }, [featuredOrder]); // eslint-disable-line

  if (isLoading) return null;
  const updateCreator = async () => {
    if (!record) return null;
    await update("creators", {
      id: record.id,
      data: { ...record, featuredOrder},
      previousData: record,
    });
    setFeaturedOrder(featuredOrder);
    handleClose();
  };

  const isAccepted =
    record?.applicationRequest === "Approved" ? "Approved" : "Approve";
  const isRejected =
    record?.applicationRequest === "Rejected" ? "Rejected" : "Reject";

  return (
    <>
      <SimpleForm>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ImageField
              sx={{
                "& img": {
                  maxWidth: 100,
                  maxHeight: 100,
                  objectFit: "contain",
                },
              }}
              source="user.profileUrl"
              title="profile"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CustomTextInput disabled label="ID" source="id" />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInput disabled label="Email" source="email" />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInput disabled label="Username" source="user.username" />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInput
              disabled
              label="First Name"
              source="user.firstName"
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInput
              disabled
              label="Last Name"
              source="user.lastName"
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInput disabled label="Bio" source="user.bio" />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInput
              disabled
              label="Bank Connected"
              source="bankConnected"
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInput
              disabled
              label="Suspended At"
              source="user.suspendedAt"
              type="date"
            />
          </Grid>
        </Grid>

        <Button
          sx={{ marginBottom: "1rem" }}
          label={`${creatorApplicationOpen ? "Hide" : "Show"
            } Creator Application`}
          variant="contained"
          onClick={() => setCreatorApplicationOpen((open) => !open)}
        />

        {creatorApplicationOpen && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <CustomTextInput disabled label="Status" source="status" />
              </Grid>
              <Grid item xs={4}>
                <CustomTextInput disabled label="Type" source="type" />
              </Grid>
              <Grid item xs={4}>
                <CustomTextInput
                  disabled
                  label="Created At"
                  source="createdAt"
                  type="date"
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextInput
                  disabled
                  label="Updated At"
                  source="updatedAt"
                  type="date"
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextInput
                  disabled
                  label="Applied At"
                  source="appliedAt"
                  type="date"
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextInput
                  disabled
                  label="Accepted At"
                  source="acceptedAt"
                  type="date"
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextInput
                  disabled
                  label="Rejected At"
                  source="rejectedAt"
                  type="date"
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextInput disabled label="Email" source="email" />
              </Grid>
              <Grid item xs={4}>
                <CustomTextInput
                  disabled
                  label="Phone Number"
                  source="phoneNumber"
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextInput
                  disabled
                  label="Subscription Price"
                  source="publicationPrice"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <CustomTextInput
                  disabled
                  label="Youtube"
                  source="socialLinks.youtube"
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextInput
                  disabled
                  label="Instagram"
                  source="socialLinks.instagram"
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextInput
                  disabled
                  label="Twitter"
                  source="socialLinks.twitter"
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextInput
                  disabled
                  label="Twitch"
                  source="socialLinks.twitch"
                />
              </Grid>
              <Grid item xs={4}>
                <CustomTextInput
                  disabled
                  label="TikTok"
                  source="socialLinks.tikTok"
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <BooleanInput
              label="Is Foreign Account"
              source="foreignAccount"
              onChange={(event) => {
                setForeignAccount(event.target.checked);
              }} 
            />
          </Grid>
        </Grid>
        
        <Grid container spacing={2}> 
          <Grid item xs={4}>
            <BooleanInput
              label="Is Featured"
              source="isFeatured"
              onChange={(event) => {
                setIsFeatured(event.target.checked);
              }}
            />
          </Grid>
          {isFeatured && featuredOrder > 0 && (
            <Grid item xs={4}>
              <Button
                label="Edit Featured"
                variant="contained"
                onClick={handleOpen}
              />
              <CustomModal
                open={open}
                handleClose={handleClose}
                handleOpen={handleOpen}
              >
                <FunctionField
                  render={(record: Creator) => (
                    <div>
                      {featuredCreators.map((creator, i) => (
                        <p key={creator.id}>
                          {i + 1}. {creator.username}
                        </p>
                      ))}

                      <div
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                          marginBottom: "1rem",
                        }}
                      >
                        <h4>Change Order to...</h4>
                        <Select
                          value={featuredOrder.toString()}
                          onChange={(e: SelectChangeEvent) =>
                            setFeaturedOrder(Number(e.target.value))
                          }
                        >
                          {record.featuredCreatorIds.map((_, i) => (
                            <MenuItem key={i} value={(i + 1).toString()}>
                              {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                      <div
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                        }}
                      >
                        <Button
                          variant="contained"
                          label="Cancel"
                          onClick={handleClose}
                        />
                        <Button
                          variant="contained"
                          label="Save"
                          onClick={updateCreator}
                        />
                      </div>
                    </div>
                  )}
                />
              </CustomModal>
            </Grid>
          )}
        </Grid>

        {isFeatured && featuredOrder > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CustomTextInput
                disabled
                label="Feature Order"
                source="featuredOrder"
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextInput
                disabled
                label="Featured Since"
                source="featured"
                type="date"
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <BooleanInput source="suspended" />
          </Grid>
          <Grid item xs={4}>
            <Button
              disabled={!(record?.user?.username && record?.postCount)}
              LinkComponent={"a"}
              href={`#/posts?filter=${encodeURIComponent(
                JSON.stringify({ creator: record?.user?.username })
              )}`}
              label="View Posts"
              variant="contained"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CustomSelectInput
              label="Categories"
              source="categoryIds"
              choices={
                record?.categories?.map((cat) => ({
                  id: cat.id,
                  name: cat.name,
                })) || []
              }
            />
          </Grid>
          <Grid item xs={4}>
            <RadioButtonGroupInput
              source="applicationRequest"
              label="Creator Application Request"
              choices={[
                { id: isAccepted, name: isAccepted },
                { id: isRejected, name: isRejected },
              ]}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </>
  );
};

interface Creator {
  id: string;
  email: string;
  type: string;
  acceptedAt: string | null;
  rejectedAt: string | null;
  updatedAt: string | null;
  createdAt: string;
  deleted: boolean;
  postCount?: number;
  featured: string | null;
  categoryIds: string[];
  categories: { name: string; id: string }[];
  applicationRequest?: string;
  bankConnected?: boolean;
  user: { username: string };
  featuredCreators: { username: string; id: string }[];
  featuredOrder: number;
  featuredCreatorIds: string[];
  isFeatured: boolean;
  foreignAccount: boolean;
}
