import * as React from "react";
import {
  Create,
  Edit,
  SimpleForm,
  List,
  DateField,
  ImageField,
  DateTimeInput,
  Datagrid,
  TextInput,
  TextField,
  PasswordInput,
  BooleanInput,
  required,
  useDataProvider,
  Validator,
  Button,
  Pagination,
  useListController,
  useListContext,
  ListContext,
  FilterLiveSearch,
  FilterListItem,
  FilterList
} from "react-admin";
import { Card, CardContent } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { useParams } from "react-router-dom";
const postRowStyle = (record: { nb_views: number }, index: any) => ({
  backgroundColor: index % 2 === 0 ? "#EEE" : "white",
});

export const UserFilterSidebar = () => (
  <Card>
    <CardContent>
      <FilterLiveSearch />
    <FilterList label="Browse" icon={<PeopleIcon/>}>
        <FilterListItem label="Browse All" value={{browse: 'true'}} />
      </FilterList>
    </CardContent>
  </Card>
)

export const UserList = (props: any) => {
  const listController = useListController();
  const listContext = useListContext(listController);

  React.useEffect(() => {
    const currentUrl = window.location.href;
    const fresh = currentUrl.split("/").pop();

    if (fresh === "users") {
      listContext.setFilters({}, []);
      // set the url to the current url without the query string
      window.history.replaceState({}, "", currentUrl.split("?")[0]);
    }
  }, [listContext.filterValues]);

  const PostPagination = () => {
    return (
      <Pagination rowsPerPageOptions={[100, 250, 500, 1000]}/>
    )
  }

  return (
    <List
      {...props}
      pagination={<PostPagination/>}
      aside={<UserFilterSidebar/>}
    >
      <Datagrid
        rowClick="edit"
        rowStyle={postRowStyle}
        bulkActionButtons={false}
        sx={{
          "& .RaDatagrid-headerCell": {
            fontSize: "1rem",
            fontWeight: 800,
          },
        }}
      >
        <TextField source="username" />
        <ImageField
          sx={{
            "& img": { maxWidth: 100, maxHeight: 100, objectFit: "contain" },
          }}
          source="profileUrl"
          label="Avatar"
          emptyText="[no avatar]"
        />
        <TextField source="email" label="Email"/>
        <DateField source="createdAt" />
        <TextField label="Deleted" source="deleted" />
        <TextField source="type" label="Type" emptyText="subscriber"/>
      </Datagrid>
    </List>
  );
};

export const UserCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="username" validate={required()} />
      <TextInput source="email" validate={required()} />
      <PasswordInput source="password" initiallyVisible validate={required()} />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
    </SimpleForm>
  </Create>
);

export const CustomTextInput = ({
  label,
  source,
  disabled,
  validate,
  type = "text",
  multiline = false,
}: {
  label: string;
  source: string;
  disabled?: boolean;
  validate?: Validator | Validator[] | undefined;
  type?: "date" | "text" | "boolean";
  multiline?: boolean;
}) => {
  return (
    <>
      <p style={{ fontSize: "small", marginTop: 0, marginBottom: "5px" }}>
        {label}
      </p>
      {type === "text" && (
        <TextInput
          disabled={!!disabled}
          validate={validate}
          label={false}
          source={source}
          multiline={multiline}
          sx={{
            marginTop: "0",
            marginBottom: "0",
            "& .MuiFilledInput-input": {
              paddingY: "5px",
              width: "400px",
            },
            "& .MuiFilledInput-root": {
              paddingY: "5px",
            },
          }}
        />
      )}

      {type === "date" && (
        <DateTimeInput
          disabled={!!disabled}
          validate={validate}
          label={false}
          source={source}
          sx={{
            marginTop: "0",
            marginBottom: "0",
            "& .MuiFilledInput-input": {
              paddingY: "5px",
              width: "400px",
            },
          }}
        />
      )}
    </>
  );
};

export const UserEdit = () => {
  const params = useParams();
  const id = params.id as string;
  const dataProvider = useDataProvider();
  const [isReported, setIsReported] = React.useState(false);
  const [userLinkComponent, setUserLinkComponent] = React.useState(
    <p
      style={{
        fontSize: "small",
        marginTop: 0,
        marginBottom: "5px",
      }}
    >
      Loading...
    </p>
  );

  React.useEffect(() => {
    if (id) {
      dataProvider
        .getOne("users", { id: id })
        .then((user: any) => {
          if (user.data.reported) {
            setIsReported(true);
          }
          if (user.data.hasApplication) {
            setUserLinkComponent(
              <Button
                label="View Creator Application"
                variant="contained"
                onClick={() => {
                  window.open(`#/creators/${id}?expand=true`);
                }}
              />
            );
          } else {
            setUserLinkComponent(
              <p
                style={{
                  color: "red",
                  fontSize: "small",
                  marginTop: 0,
                  marginBottom: "5px",
                }}
              >
                This user does not have a creator application
              </p>
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dataProvider, id]);

  return (
    <Edit title={"Users"}>
      <SimpleForm>
        <CustomTextInput label="Id" disabled source="id" />
        <CustomTextInput label="Username" disabled source="username" />
        <CustomTextInput
          label="Email"
          disabled
          source="email"
          validate={required()}
        />
        <CustomTextInput label="First Name" disabled source="firstName" />
        <CustomTextInput label="Last Name" disabled source="lastName" />
        <CustomTextInput label="Bio" disabled source="bio" multiline={true} />
        <CustomTextInput
          label="Updated At"
          disabled
          source="updatedAt"
          type="date"
        />
        <CustomTextInput
          label="Created At"
          disabled
          source="createdAt"
          type="date"
        />
        <CustomTextInput
          label="Date Of Birth"
          source="dateOfBirth"
          type="date"
        />
        <CustomTextInput
          label="Bank Connected"
          disabled
          source="bankConnected"
        />
        <CustomTextInput
          disabled
          label="Suspended since"
          source="suspendedAt"
          type="date"
        />
        <BooleanInput source="suspended" />
        <BooleanInput source="disabled" />
        <BooleanInput source="isAdmin" label="Is Admin" />
        {userLinkComponent}
      </SimpleForm>
    </Edit>
  );
};
