import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  required,
  Button,
  useDataProvider,
  useListController,
  useListContext,
} from "react-admin";
import { useParams } from "react-router-dom";

const postRowStyle = (record: { nb_views: number }, index: any) => ({
  backgroundColor: index % 2 === 0 ? "#EEE" : "white",
});

export const AdminCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput label="User ID" source="id" validate={[required()]} />
    </SimpleForm>
  </Create>
);

const userFilters = [<TextInput label="Search" source="q" alwaysOn />];

export const AdminList = (props: any) => {
  const listController = useListController();
  const listContext = useListContext(listController);

  React.useEffect(() => {
    const currentUrl = window.location.href;
    const fresh = currentUrl.split("/").pop();

    if (fresh === "admins") {
      listContext.setFilters({}, []);
      // set the url to the current url without the query string
      window.history.replaceState({}, "", currentUrl.split("?")[0]);
    }
  }, [listContext.filterValues]);
  return (
    <List {...props} filter={{ withDeleted: true }} filters={userFilters}>
      <Datagrid
        rowStyle={postRowStyle}
        rowClick="edit"
        bulkActionButtons={false}
        sx={{
          "& .RaDatagrid-headerCell": {
            fontSize: "1rem",
            fontWeight: 800,
          },
        }}
      >
        <TextField label="User ID" source="id" />
        <TextField label="Username" source="user.username" />
        <TextField label="First Name" source="user.firstName" />
        <TextField label="Last Name" source="user.lastName" />
      </Datagrid>
    </List>
  );
};

export const CustomTextInput = ({
  label,
  source,
  disabled,
}: {
  label: string;
  source: string;
  disabled?: boolean;
}) => {
  return (
    <>
      <p style={{ fontSize: "small", marginTop: 0, marginBottom: "5px" }}>
        {label}
      </p>
      <TextInput
        disabled={!!disabled}
        label={false}
        source={source}
        sx={{
          marginTop: "0",
          marginBottom: "0",
          "& .MuiFilledInput-input": {
            paddingY: "5px",
            width: "400px",
          },
        }}
      />
    </>
  );
};

export const AdminEdit = () => {
  const params = useParams();
  const id = params.id as string;
  const dataProvider = useDataProvider();
  const [userLinkComponent, setUserLinkComponent] = React.useState(
    <p
      style={{
        fontSize: "small",
        marginTop: 0,
        marginBottom: "5px",
      }}
    >
      Loading...
    </p>
  );

  React.useEffect(() => {
    dataProvider
      .getOne("admins", { id: params.id })
      .then((adminData: any) => {
        if (adminData) {
          if (adminData.data.user) {
            setUserLinkComponent(
              <Button
                label="View User Profile"
                variant="contained"
                onClick={() => {
                  const newUrl = "#/users/" + id;
                  window.open(newUrl);
                }}
              />
            );
          } else {
            setUserLinkComponent(
              <p
                style={{
                  color: "red",
                  fontSize: "small",
                  marginTop: 0,
                  marginBottom: "5px",
                }}
              >
                This admin does not have a user profile
              </p>
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dataProvider, params.id, id]);

  return (
    <Edit title={"Admins"}>
      <SimpleForm toolbar={<></>}>
        <CustomTextInput disabled label="Id" source="id" />
        <CustomTextInput disabled label="Username" source="user.username"/>
        <CustomTextInput disabled label="First Name" source="user.firstName" />
        <CustomTextInput disabled label="Last Name" source="user.lastName" />
        {userLinkComponent}
      </SimpleForm>
    </Edit>
  );
};
